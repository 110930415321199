<template>
  <a-row>
    <a-col>
      <a-card title="1、销售整体水平事业">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableOne"/>
        <chartComponent :chart-data="chartData.chartOne"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="2、售后整体水平事业(未区分车型)">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableTwo"/>
        <chartComponent :chart-data="chartData.chartTwo"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="3、销售水平事业-新车用品">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableThere"/>
        <chart-component :chart-data="chartData.chartThere"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="4、销售水平事业-金融按揭">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableFour"/>
        <chart-component :chart-data="chartData.chartFour"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="5、销售水平事业-新车保险">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableFive"/>
        <chart-component :chart-data="chartData.chartFive"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="6、销售水平事业-上牌验车">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableSix"/>
        <chart-component :chart-data="chartData.chartSix"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="7、销售水平事业-新车延保">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableSeven"/>
        <chart-component :chart-data="chartData.chartSeven"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="8、销售水平事业-销售自主服务项目">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableEight"/>
        <chart-component :chart-data="chartData.chartEight"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="9、销售水平事业-置换业务(未区分车型)">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableNine"/>
        <chart-component :chart-data="chartData.chartNine"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="10、售后水平事业-售后续保(未区分车型)">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableTen"/>
        <chart-component :chart-data="chartData.chartTen"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="11、售后水平事业-非新车延保(未区分车型)">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableEleven"/>
        <chart-component :chart-data="chartData.chartEleven"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="12、售后水平事业-售后自主服务项目(未区分车型)">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableTwelve"/>
        <chart-component :chart-data="chartData.chartTwelve"/>
      </a-card>
    </a-col>
    <a-col>
      <a-card title="13、二手车(未区分车型)">
        <monthReportTableComponent :columns-data="columnsData" :table-data="tableData.tableThirteen"/>
        <chart-component :chart-data="chartData.chartThirteen"/>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { columnsData, getTableValues, tableData, chartData, getChartRows, columnsDataAgv } from "./kpiCode.js";
import monthReportTableComponent from "@/components/monthReportTableComponent";
import chartComponent from "@/components/chartComponent";

export default {
  props: {
    paramData: Object,
    chartValue: Object,
    carSelect: Object,
  },
  components: { monthReportTableComponent, chartComponent },
  data() {
    return {
      columnsData, tableData, chartData,columnsDataAgv,
    };
  },
  methods: {},
  watch: {
    paramData: {
      handler: function() {
        getTableValues(this.paramData, this.carSelect);
        getChartRows(this.chartValue, this.carSelect);
      },
      deep: true,
    },
    carSelect: {
      handler: function() {
        getTableValues(this.paramData, this.carSelect);
        getChartRows(this.chartValue, this.carSelect);
      },
      deep: true,
    },
    chartValue: {
      handler: function() {
        getTableValues(this.paramData, this.carSelect);
        getChartRows(this.chartValue, this.carSelect);
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/views/monthReport/analysisReport/childComponent/storeManagment.less";

.chart-border {
  margin-top: 30px;
  }

.ant-col {
  padding: 4px 0 !important;
  }
</style>
