<template>
  <div>
    <a-descriptions bordered :column="3" style="padding-top: 10px">
      <a-descriptions-item v-for="(item,arrIndex) of areaData" :key="arrIndex">{{ item.val }}
        <div slot="label">
          <span v-if="arrIndex===2">
            <a-tooltip placement="top" title="占比超100%及以上，考虑新车（一级+二级）毛利负数" arrow-point-at-center>{{ item.title }} <a-icon type="question-circle" /></a-tooltip>
            </span>
          <span v-else>{{ item.title }}</span>
        </div>
      </a-descriptions-item>

    </a-descriptions>
    <div class="info">
      <p> 1、店均销售水平事业盈利能力：<span v-html="infoHtml.data1"/></p>
      <p> 2、店均售后水平事业盈利能力：<span v-html="infoHtml.data2"/></p>
    </div>
  </div>
</template>

<script>
import { areaData, infoData, isAddAvg } from "@/views/performanceReport/NationalPerformanceReport/kpiCode";
import { UnitChartProcessing, UnitProcessing } from "@/until/option";

export default {
  props: {
    paramData: Object,
    carChange: Object,
  },
  data() {
    return {
      areaData, infoData,
      infoHtml: {
        data1: "",
        data2: "",
      },
    };
  },
  mounted() {
    this.getAreaData();
  },
  methods: {
    getAreaData() {
      Object.keys(this.paramData).forEach(d => {
        areaData.forEach(data => {
          const kpiCode = isAddAvg(data, 0);
          if(kpiCode === d) {
            data.val = UnitProcessing(this.paramData[d].Unit, this.paramData[d].Current);
          }
        });
      });
      this.getInfoData();
    },
    getInfoData() {
      const thanSize = (data) => {
        if(data.val > data.LastMonth) return `${data.title}<b style="color: #1890FF">上升至${UnitChartProcessing(data.Unit, data.val) ?? 0}${data.Unit}</b>; `;
        return `${data.title}<b style="color: red">下降至${UnitChartProcessing(data.Unit, (data.val ?? 0)) ?? 0}${data.Unit}</b>; `;
      };
      this.infoHtml.data1 = "";
      this.infoHtml.data2 = "";
      let grossProfit = "";
      let afterSales = "";
      Object.keys(this.paramData).forEach(d => {
        infoData.forEach((data, i) => {
          const kpiCode = isAddAvg(data, 0);
          if(kpiCode === d) {
            data.val = this.paramData[d].Current;
            data.LastMonth = this.paramData[d].LastMonth;
            data.Unit = this.paramData[d].Unit;
            let isThan = thanSize(data);
            if(data.title === "平均单车销售水平事业毛利") {
              grossProfit = isThan;
              return;
            }
            if(data.title === "平均单车售后水平事业毛利") {
              afterSales = isThan;
              return;
            }
            if(i <= 2) {
              this.infoHtml.data1 += isThan;
            } else {
              this.infoHtml.data2 += isThan;
            }
          }
        });
      });
      this.infoHtml.data1 += grossProfit;
      this.infoHtml.data2 += afterSales;
    },
  },
  watch: {
    paramData: {
      handler: function() {
        this.getAreaData();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  padding-top: 20px;
  }
</style>
